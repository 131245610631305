import { getSavedCardsByPlaceIdFx } from './../savedCards/effects/getSavedCardsByPlaceIdFx';
import {
  $avaliableOrderPaymentTypes,
  $paymentTypes,
  $timeTo,
  $urgent,
  orderingForm,
} from 'domains/cart';
import { $stopLists } from 'domains/catalog';
import { sample } from 'effector';
import { Address, OrderType } from 'models';
import { reset, spread } from 'patronum';
import {
  fetchCitiesFx,
  fetchRestaurantsFx,
  getDeliveryZoneFx,
  fetchAddressesByRequestFx,
  fetchDeliveryZoneByGeolocationFx,
  fetchDeliveryZonesAndMapLayerZones,
  getRestaurantDepsFx,
  initGeolocationFx,
  getCityDeliveryZoneFx,
  checkCityFx,
} from './effects';
import { AddressForm, Restaurant } from './entities';
import {
  changeAddress,
  setAddressesList,
  setCitiesList,
  changeCity,
  changeUserGeolocation,
  changeType,
  changeRestaurant,
  setTimeIntervals,
  changeAddressRequest,
  submitAddress,
  submitRestaurant,
  closeAddressConfirmation,
  changeCountry,
  closeCityInformationBanner,
} from './events';
import {
  $address,
  $addressesList,
  $citiesList,
  $city,
  $userGeolocation,
  $type,
  $restaurant,
  $timeIntervals,
  $restaurantsList,
  addressForm,
  cartographyGate,
  $hasRestaurantsError,
  $deliveryZone,
  $deliveryZoneError,
  $showAddressConfirmation,
  $visibleDeliveryZone,
  $deliveryZonesAndMapLayerZones,
  $noRestaurantsInCityError,
  $allCitiesList,
  $country,
  $currency,
  $showPlaceholder,
  $noDeliveryPlaceholder,
  $cityInformationBanner,
} from './stores';
import { FELocalStorageUtil } from 'utils';
import { AuthClient, BaseErrorResponse } from 'api/base';
import { getFeaturesByPlaceIdFx } from 'domains/features';
import { GET_CURRENCY_SYMBOL } from 'const';
import { initCatalogFx } from 'domains/application';

$address.on(changeAddress, (_, address) => address);
$addressesList.on(setAddressesList, (_, addressesList) => addressesList);
$citiesList.on(setCitiesList, (_, citiesList) => citiesList);
$city.on(changeCity, (_, city) => city);
//@ts-ignore
$userGeolocation.on(
  changeUserGeolocation,
  (_, userGeolocation) => userGeolocation,
);
$type.on(changeType, (_, type) => type);
$restaurant.on(changeRestaurant, (_, restaurant) => restaurant);
$timeIntervals.on(setTimeIntervals, (_, timeIntervals) => timeIntervals);

//address
sample({
  clock: changeAddressRequest,
  source: $city,
  filter: (city, query) => !!city && !!query,
  fn: (city, query) => ({
    cityId: city!.id,
    query: query ?? '',
  }),
  target: fetchAddressesByRequestFx,
});
sample({
  clock: changeAddressRequest,
  filter: (query) => !query,
  fn: () => ({ list: [], placeholder: false }),
  target: spread({
    targets: { list: setAddressesList, placeholder: $showPlaceholder },
  }),
});
sample({
  clock: fetchAddressesByRequestFx.doneData,
  fn: (payload) => ({ list: payload.items, placeholder: false }),
  target: spread({
    targets: { list: setAddressesList, placeholder: $showPlaceholder },
  }),
});
sample({
  clock: fetchAddressesByRequestFx.failData,
  fn: () => ({ list: [], placeholder: true }),
  target: spread({
    targets: { list: setAddressesList, placeholder: $showPlaceholder },
  }),
});
sample({
  clock: changeAddress,
  source: $city,
  filter: (_, address) => address !== null,
  fn: (city, address) => ({ geolocation: address!.location, cityId: city!.id }),
  target: getDeliveryZoneFx,
});

spread({
  //@ts-expect-error
  source: sample({
    clock: getDeliveryZoneFx.doneData,
    fn: (payload) => ({
      paymentTypes: payload.paymentTypes,
      avaliableOrderPaymentTypes: payload.paymentTypes,
      paymentType: payload.paymentType,
      timeIntervals: payload.timeIntervals,
      deliveryZone: payload.deliveryZone,
      stopLists: payload.stopLists,
      timeTo: payload.timeTo,
      urgent: payload.urgent,
      error: null,
      placeId: payload.deliveryZone.id,
    }),
  }),
  targets: {
    placeId: getFeaturesByPlaceIdFx,
    deliveryZone: $deliveryZone,
    paymentTypes: $paymentTypes,
    paymentType: orderingForm.fields.paymentType.$value,
    avaliableOrderPaymentTypes: $avaliableOrderPaymentTypes,
    timeIntervals: $timeIntervals,
    stopLists: $stopLists,
    error: $deliveryZoneError,
    timeTo: $timeTo,
    urgent: $urgent,
  },
});

sample({
  clock: getDeliveryZoneFx.doneData,
  filter: () => AuthClient.isAuth,
  fn: ({ deliveryZone }) => deliveryZone.id,
  target: getSavedCardsByPlaceIdFx,
});

spread({
  //@ts-expect-error
  source: sample({
    clock: changeRestaurant,
    filter: (payload) => Boolean(payload),
    fn: (payload) => ({
      paymentTypes: payload!.availablePaymentTypes,
      paymentType:
        payload!.availablePaymentTypes.filter(
          (paymentType) => paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
        ).length === 1
          ? payload!.availablePaymentTypes.filter(
              (paymentType) =>
                paymentType !== 'BONUS' && paymentType !== 'SBERPAY',
            )[0]
          : null,
      avaliableOrderPaymentTypes: payload!.availablePaymentTypes,
      restaurant: { restaurant: payload! },
      deliveryZone: null,
      error: null,
      placeId: payload!.id,
    }),
  }),
  targets: {
    placeId: getFeaturesByPlaceIdFx,
    restaurant: getRestaurantDepsFx,
    deliveryZone: $deliveryZone,
    paymentTypes: $paymentTypes,
    paymentType: orderingForm.fields.paymentType.$value,
    avaliableOrderPaymentTypes: $avaliableOrderPaymentTypes,
    error: $deliveryZoneError,
  },
});
sample({
  clock: changeRestaurant,
  filter: (restaurant) => restaurant !== null && AuthClient.isAuth,
  fn: (restaurant) => restaurant!.id,
  target: getSavedCardsByPlaceIdFx,
});

sample({
  clock: changeRestaurant,
  filter: (restaurant) => !!restaurant && AuthClient.isAuth,
  fn: (payload) => payload!.id,
  target: getSavedCardsByPlaceIdFx,
});

spread({
  //@ts-expect-error
  source: sample({
    clock: getRestaurantDepsFx.doneData,
    fn: (payload) => ({ ...payload }),
  }),
  targets: {
    timeTo: $timeTo,
    urgent: $urgent,
    timeIntervals: $timeIntervals,
    stopLists: $stopLists,
  },
});
spread({
  //@ts-expect-error
  source: sample({
    clock: getRestaurantDepsFx.failData,
    fn: () => ({
      timeIntervals: null,
      stopLists: null,
    }),
  }),
  targets: {
    timeIntervals: $timeIntervals,
    stopLists: $stopLists,
  },
});

spread({
  //@ts-expect-error
  source: sample({
    clock: getDeliveryZoneFx.failData,
    fn: (res) => {
      const error = (res as BaseErrorResponse.ErrorResponse).errors
        ? (
            (res as BaseErrorResponse.ErrorResponse)
              .errors as BaseErrorResponse.ErrorInResponse[]
          )[0]
        : null;

      return {
        paymentTypes: [],
        avaliableOrderPaymentTypes: [],
        timeIntervals: [],
        paymentType: null,
        stopLists: null,
        deliveryZone: null,
        error: error?.presentationData?.message || '',
      };
    },
  }),
  targets: {
    deliveryZone: $deliveryZone,
    paymentTypes: $paymentTypes,
    avaliableOrderPaymentTypes: $avaliableOrderPaymentTypes,
    paymentType: orderingForm.fields.paymentType.$value,
    timeIntervals: $timeIntervals,
    stopLists: $stopLists,
    error: $deliveryZoneError,
  },
});

spread({
  //@ts-expect-error
  source: sample({
    clock: fetchDeliveryZoneByGeolocationFx.doneData,
    fn: (payload) => ({ hasError: null, deliveryZone: payload }),
  }),
  targets: {
    hasError: $deliveryZoneError,
    deliveryZone: $visibleDeliveryZone,
  },
});
sample({
  clock: fetchDeliveryZoneByGeolocationFx.failData,
  fn: (res) => {
    const error = (res as BaseErrorResponse.ErrorResponse).errors
      ? (
          (res as BaseErrorResponse.ErrorResponse)
            .errors as BaseErrorResponse.ErrorInResponse[]
        )[0]
      : null;

    return error?.presentationData?.message || '';
  },
  target: $deliveryZoneError,
});

//restaurant
sample({
  clock: cartographyGate.open,
  source: $city,
  filter: (city) => Boolean(city),
  fn: (city) => ({ cityId: city!.id }),
  target: fetchRestaurantsFx,
});
// sample({
//   clock: changeRestaurant,
//   filter: (restaurant) => restaurant !== null,
//   fn: (restaurant) => ({ id: restaurant!.id }),
//   target: fetchRestaurantTimeIntervalsFx,
// });

spread({
  source: sample({
    clock: fetchRestaurantsFx.doneData,
    fn: (payload) => ({ restaurants: payload.items, error: false }),
  }),
  targets: {
    restaurants: $restaurantsList,
    error: $hasRestaurantsError,
  },
});
sample({
  clock: fetchRestaurantsFx.doneData,
  fn: (restaurants) => restaurants.count === 0,
  target: $noRestaurantsInCityError,
});
//@ts-ignore
spread({
  source: sample({
    clock: fetchRestaurantsFx.failData,
    fn: () => ({ restaurants: [], error: true }),
  }),
  targets: {
    restaurants: $restaurantsList,
    error: $hasRestaurantsError,
  },
});
sample({
  clock: fetchRestaurantsFx.failData,
  fn: () => false,
  target: $noRestaurantsInCityError,
});

//set cities after fetch
sample({
  clock: fetchCitiesFx.doneData,
  fn: (data) => data.items.sort((a, b) => a.name.localeCompare(b.name)),
  target: setCitiesList,
});

//reset cities array after error
$citiesList.reset(fetchCitiesFx.failData);
sample({
  clock: changeCity,
  filter: (city) => city !== null,
  fn: (city) => ({ city: { cityId: city!.id }, zoneError: null }),
  target: spread({
    targets: { city: fetchRestaurantsFx, zoneError: $noDeliveryPlaceholder },
  }),
});
sample({
  clock: changeCity,
  filter: (city) => city !== null,
  fn: (city) => ({ location: city!.location, cityId: city!.id }),
  target: getCityDeliveryZoneFx,
});
sample({
  clock: changeCity,
  filter: (city) => city !== null,
  fn: (city) => ({ id: city!.id }),
  target: checkCityFx,
});
sample({
  clock: initCatalogFx.doneData,
  filter: ({ currentCity }) => currentCity !== null,
  fn: ({ currentCity }) => ({
    location: currentCity!.location,
    cityId: currentCity!.id,
  }),
  target: getCityDeliveryZoneFx,
});
sample({
  clock: getCityDeliveryZoneFx.failData,
  fn: () => 'cartography.address.form.noDeliveryPlaceholder',
  target: $noDeliveryPlaceholder,
});

//reset stores after picking a new city
reset({
  clock: sample({
    clock: changeCountry,
  }),
  target: [
    $city,
    $address,
    $addressesList,
    $type,
    $restaurant,
    $deliveryZone,
    $restaurantsList,
    $timeIntervals,
  ],
});

sample({
  clock: [changeCity, changeCountry],
  target: addressForm.reset,
});
reset({
  clock: sample({
    clock: changeCity,
  }),
  target: [
    $address,
    $addressesList,
    $type,
    $restaurant,
    $deliveryZone,
    $restaurantsList,
    $timeIntervals,
  ],
});

//submitting address
spread({
  //@ts-ignore
  source: sample({
    clock: submitAddress,
    source: { $city, $country },
    fn: (
      { $city: city, $country: country },
      payload,
    ): {
      type: Exclude<OrderType, 'IN_PLACE'>;
      address: Address.ReceivingAddressV2;
      restaurant: null;
    } => {
      FELocalStorageUtil.setCartography({
        city: city!,
        country: country!,
        type: 'COURIER',
        address: payload.currentAddress,
        restaurant: null,
      });
      return {
        type: 'COURIER',
        address: payload.currentAddress,
        restaurant: null,
      };
    },
  }),
  targets: {
    type: changeType,
    address: changeAddress,
    restaurant: changeRestaurant,
  },
});

//submit restaurant
spread({
  //@ts-ignore
  source: sample({
    clock: submitRestaurant,
    source: { $city, $country },
    fn: (
      { $city: city, $country: country },
      payload,
    ): {
      type: Exclude<OrderType, 'IN_PLACE'>;
      address: null;
      restaurant: Restaurant;
    } => {
      FELocalStorageUtil.setCartography({
        city: city!,
        country: country!,
        type: 'GO_TO_PLACE',
        address: null,
        restaurant: payload.currentRestaurant,
      });
      return {
        type: 'GO_TO_PLACE',
        address: null,
        restaurant: payload.currentRestaurant,
      };
    },
  }),
  targets: {
    type: changeType,
    address: changeAddress,
    restaurant: changeRestaurant,
  },
});

//gate
const onCartographyOpen = sample({
  clock: cartographyGate.open,
  source: { $type, $address, $restaurant },
  fn: ({ $address, $restaurant, $type }) => {
    return {
      address: $address,
      restaurant: $restaurant,
      type: $type,
    };
  },
});

//set address from after opening cartography
sample({
  clock: onCartographyOpen,
  fn: ({ address }): Partial<AddressForm> => {
    return {
      address: address?.address || null,
      apartment: address?.apartment || null,
      isFinal: Boolean(address) ? Boolean(address?.house) : false,
      isPrivate: Boolean(address) ? !Boolean(address?.apartment) : false,
      housing: address?.housing || null,
      floor: address?.floor || null,
      entrance: address?.entrance || null,
      finalAddress: address
        ? {
            address: address.address,
            house: address.house,
            location: address.location,
            details: address.details || '',
            key: '',
            street: address.street,
            final: Boolean(address.house),
            cityId: address.cityId,
          }
        : null,
    };
  },
  target: addressForm.set,
});

//reset address from
sample({
  clock: [cartographyGate.close, submitRestaurant, changeCity],
  target: addressForm.reset,
});

sample({
  clock: cartographyGate.close,
  fn: () => null,
  target: $visibleDeliveryZone,
});
sample({
  clock: cartographyGate.open,
  source: $deliveryZone,
  fn: (deliveryZone) => deliveryZone,
  target: $visibleDeliveryZone,
});

sample({
  clock: closeAddressConfirmation,
  fn: () => false,
  target: $showAddressConfirmation,
});

//deliveryZonesAndMapLayerZones after city changes
sample({
  clock: changeCity,
  filter: (city) => city !== null,
  fn: (city) => ({ cityId: city!.id }),
  target: fetchDeliveryZonesAndMapLayerZones,
});

sample({
  clock: fetchDeliveryZonesAndMapLayerZones.doneData,
  fn: (payload) => payload,
  target: $deliveryZonesAndMapLayerZones,
});
sample({
  clock: fetchDeliveryZonesAndMapLayerZones.failData,
  fn: () => [],
  target: $deliveryZonesAndMapLayerZones,
});

sample({
  clock: initGeolocationFx.doneData,
  fn: (userGeolocation) => userGeolocation,
  target: $userGeolocation,
});

//@ts-expect-error
spread({
  source: sample({
    clock: changeCountry,
    source: { $allCitiesList },
    filter: (_, country) => country !== null,
    fn: ({ $allCitiesList: allCitiesList }, country) => ({
      country: country!,
      cities: allCitiesList.filter((city) => city.countryId === country!.id),
      currency: GET_CURRENCY_SYMBOL[country!.currency],
    }),
  }),
  targets: {
    country: $country,
    cities: $citiesList,
    currency: $currency,
  },
});
spread({
  //@ts-expect-error
  source: sample({
    clock: changeCountry,
    filter: (country) => !country,
    fn: () => ({
      country: null,
      cities: [],
      currency: GET_CURRENCY_SYMBOL['RUB'],
    }),
  }),
  targets: {
    country: $country,
    cities: $citiesList,
    currency: $currency,
  },
});

sample({
  clock: checkCityFx.doneData,
  filter: (data) => !!data && !!data.alert,
  target: $cityInformationBanner,
});
sample({
  clock: closeCityInformationBanner,
  fn: () => null,
  target: $cityInformationBanner,
});
