import {
  CountryISO,
  Currency,
  CurrencySymbol,
  Language,
  Measure,
  PaymentType,
  PaymentTypeInList,
} from 'models';
import {
  IMG_IC_24_CASH,
  IMG_IC_24_CREDIT_CARD,
  IMG_IC_24_CARD,
  IMG_IC_24_SBP,
} from 'images';

export const INITIAL_LANGUAGE: Language = 'ru';

export const ISO_LANGUAGES: Record<string, Language> = {
  RU: 'ru',
  AE: 'en',
  AM: 'hy',
  KZ: 'kz',
};

export const DESKTOP_WIDTH = 1024;
export const MOBILE_WIDTH = 500;
export const ANIMATION_DELAY = 200;
export const MOBILE_MENU_WIDTH = 600;

export const GET_MEASURE: Record<Measure | 'KILOCALORIES', string> = {
  LITER: 'measures.l',
  GRAM: 'measures.gr',
  PIECE: 'measures.pcs',
  MILLILITER: 'measures.ml',
  KILOCALORIES: 'measures.kcal',
};

export const GET_PAYMENT_TYPE: Record<PaymentTypeInList, string> = {
  CARD: 'paymentTypes.CARD',
  CASH: 'paymentTypes.CASH',
  TERMINAL: 'paymentTypes.TERMINAL',
  SBP: 'СБП',
};
export const GET_ALL_PAYMENT_TYPE: Record<
  Exclude<PaymentType, 'SAVED'>,
  string
> = {
  CARD: 'paymentTypes.CARD',
  CASH: 'paymentTypes.CASH',
  TERMINAL: 'paymentTypes.TERMINAL',
  BONUS: 'paymentTypes.BONUS',
  SBERPAY: 'paymentTypes.SBERPAY',
  SBP: 'СБП',
};

export const GET_COURIER_PAYMENT_TYPE: Record<PaymentTypeInList, string> = {
  CARD: 'paymentTypes.CARD',
  CASH: 'paymentTypes.CASH',
  TERMINAL: 'paymentTypes.TERMINAL_DELIVERY',
  SBP: 'СБП',
};
export const GET_ALL_COURIER_PAYMENT_TYPE: Record<
  Exclude<PaymentType, 'SAVED'>,
  string
> = {
  CARD: 'paymentTypes.CARD',
  CASH: 'paymentTypes.CASH',
  TERMINAL: 'paymentTypes.TERMINAL_DELIVERY',
  BONUS: 'paymentTypes.BONUS',
  SBERPAY: 'paymentTypes.SBERPAY',
  SBP: 'СБП',
};

export const GET_PAYMENT_TYPE_IMAGE: Record<PaymentTypeInList, string> = {
  CARD: IMG_IC_24_CREDIT_CARD,
  CASH: IMG_IC_24_CASH,
  TERMINAL: IMG_IC_24_CARD,
  SBP: IMG_IC_24_SBP,
};

export const GET_PARAMS = {
  layout: 'layout',
  page: 'page',
  orderId: 'orderId',
  productId: 'productId',
  restaurant: 'restaurant',
};

export const GET_ENUMS = {
  page: {
    cartography: 'cartography',
    auth: 'auth',
    editProfile: 'edit-profile',
    orders: 'orders',
    cart: 'cart',
    bonus: 'bonus',
  },
};

export const GET_CURRENCY_SYMBOL: Record<Currency, CurrencySymbol> = {
  AED: 'AED',
  AMD: 'AMD',
  EUR: '€',
  RUB: '₽',
  USD: '$',
};

export const KEY_CODE = {
  backspace: 'Backspace',
  left: 'ArrowLeft',
  up: 'ArrowUp',
  right: 'ArrowRight',
  down: 'ArrowDown',
};

export const VOLGOGRAD_GEOPOSITION = { lat: 48.7194, lng: 44.5018 };
export const OFFICE_LOCATION = {
  lat: 48.711557,
  lng: 44.530178,
};

export const PROMOTIONS_PATHNAME = 'promotions';
export const LEGAL_INFORMATION_PATHNAME = 'legal-information';
export const PROFILE_PATHNAME = 'profile';
export const SAVED_CARDS_PATHNAME = 'saved-cards';
export const DELIVERY_AND_PAYMENTS_PATHNAME = 'dostavka-i-oplata';
export const RESTAURANTS_PATHNAME = 'restaurants';
export const CONTACTS_PATHNAME = 'contacts';

export const APP_STORE_LINK = process.env.REACT_APP_LINK_APPLE;
export const GOOGLE_PLAY_LINK = process.env.REACT_APP_LINK_ANDROID;
export const APP_GALLERY_LINK = process.env.REACT_APP_LINK_HUAWEI;

export const NEW_YEAR_WISHES_LINK =
  'https://redirect.appmetrica.yandex.com/serve/316921899009500350';

export const LOYALTY_PROGRAM_LINK =
  'https://docs.google.com/document/d/1GsrmB9mLjyvyz2uCIhFwoIXwFJ7vI3Jc/edit';

export const HOTLINE_PHONE_NUMBER = '8 (800) 550 22 20';

export const FEEDBACK_EMAIL = 'ask@blinberry.team';

export const JOB_EMAIL = 'rabota@blinberry.team';

export const DEVELOPMENT_EMAIL = 'development@blinberry.team';
export const DEVELOPMENT_PHONE_NUMBER = '+7 (903) 373-28-38';

export const OFFICE_EMAIL = 'office@blinberry.team';

export const SUPPLY_EMAIL = 'snab@sushivesla.team';
export const SUPPLY_PHONE_NUMBER = '+7 (927) 061-00-31';

export const MEDIA_EMAIL = 'press@sushivesla.team';

export const ADVERTISING_EMAIL = 'marketing@blinberry.team';

export const FRANCHISE_PHONE_NUMBER = '+7 (937) 533-38-17';

export const BASE_URL = 'https://web.consumer.xn--90aamkcop0a.xn--p1ai';

export const DEV_URL = 'feat.ftl-dev.ru';
export const INTL_URL = 'blinberry.com';
export const RU_URL = 'xn--90aamkcop0a.xn--p1ai';

export const PORT = '3000';
export const GET_COUNTRY_LANGUAGE_BY_ISO_CODE: Record<CountryISO, Language> = {
  AE: 'en',
  AM: 'hy',
  KZ: 'kz',
  RU: 'ru',
};
